<template>
  <div class="table__reglement_list">
    <v-data-table
      :headers="headers"
      :fixed-header="true"
      :loading="loading"
      :items="items"
      item-key="id"
      :class="{
        'filters-opened': areFiltersOpened,
        'elevation-1 list__table': scrollTable,
      }"
      :items-per-page="-1"
      :hide-default-footer="true"
    >
      <template v-slot:item.structure="{ item }">
        {{ item[0].STR_Structure && item[0].STR_Structure.NomStructure }}
      </template>
      <template v-slot:item.domaine="{ item }">
        {{ item[0].LIST_MouvementDomaine.domaine }}
      </template>
      <template v-slot:item.nombre="{ item }"> {{ item.fluxNbr }} </template>
      <template v-slot:item.montant="{ item }"> {{ item.montant }} € </template>
      <template #item.Select="{item}">
        <v-checkbox
          input-value="true"
          :value="fluxSelected"
          @change="disableFlux(item)"
        ></v-checkbox>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    scrollTable() {
      if (this.panel === 0 || this.itemsPerPage > 10 || this.itemsPerPage == -1)
        return true;
      return false;
    },
  },
  data: () => ({
    fluxSelected: false,
  }),
  methods: {
    isDisabled(item) {
      console.log("isDisabled");
      return false;
    },
    disableFlux(item) {
      this.$emit("removeItems", item);
    },
  },
  async created() {
    // console.log("this.items reglement", this.items);
  },

  props: {
    items: {
      type: Array,
      default: null,
    },

    headers: {
      type: Array,
    },
    areFiltersOpened: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: Number,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.list__table .v-data-table-header {
  background: rgba(230, 230, 230, 1);
}
th {
  background: lightgrey !important;
}

.table__reglement_list {
  .list__table .v-data-table__wrapper {
    height: calc(100vh - 420px);
  }
  .list__table.filters-opened .v-data-table__wrapper {
    height: calc(100vh - 500px);
  }

  &.buttonLine {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 480px);
    }
  }

  &.membresFederaux {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 310px);
    }
  }
}

/* .listSearch {
    height: calc(100vh - 370px);
    overflow: hidden;
    overflow-y: scroll;
} */
.filters-opened {
  height: calc(100vh - 440px);
}
.periode__class {
  color: lightslategray;
}
</style>
