import { render, staticRenderFns } from "./Sage.vue?vue&type=template&id=97448b28&scoped=true&"
import script from "./Sage.vue?vue&type=script&lang=js&"
export * from "./Sage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97448b28",
  null
  
)

export default component.exports