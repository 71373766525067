<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span v-if="panel === 0">Masquer les filtres</span>
              <span v-else>Afficher les filtres</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="#f0f0f0">
              <ValidateForm @validated="submit" :showFieldObl="false">
                <v-row class="pt-5">
                  <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.NomStructure"
                      rules="min:3"
                      placeholder="Nom Structure"
                    ></inputText>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="3"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.domaine"
                      rules="min:3"
                      placeholder="Domaine"
                    ></inputText>
                  </v-col>
                  <!-- <v-col
                    cols="2"
                    lg="3"
                    xl="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.libelle"
                      rules="min:3"
                      placeholder="Libellée règlement"
                    ></inputText>
                  </v-col> -->
                  <!-- <v-col
                    cols="2"
                    lg="3"
                    xl="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <DatePicker
                      v-model="search.StartDate"
                      rules="min:3"
                      :maxToday="true"
                      placeholder="Date début"
                    ></DatePicker>
                  </v-col> -->
                  <!-- <v-col
                    cols="2"
                    lg="3"
                    xl="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <DatePicker
                      v-model="search.EndDate"
                      rules="min:3"
                      :maxToday="true"
                      placeholder="Date fin"
                    ></DatePicker>
                  </v-col> -->
                  <!-- <v-col
                    cols="2"
                    lg="3"
                    xl="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <DatePicker
                      v-model="search.PaiementDate"
                      rules="min:3"
                      :maxToday="true"
                      placeholder="Date paiement effectif"
                    ></DatePicker>
                  </v-col> -->

                  <v-col cols="4" lg="3">
                    <v-btn
                      @click.prevent="erraseFilter()"
                      outlined
                      color="indigo"
                      >Effacer tous les filtres</v-btn
                    >
                  </v-col>
                  <v-col
                    cols="6"
                    lg="4"
                    class="pb-3"
                    style="padding-top: 10px; padding-bottom: 0"
                  >
                    <label
                      style="
                    font-size: 14px;
                    margin-bottom: 0;
                    position: absolute;
                    top: 20px;
                    left: 15px;
                  "
                      v-text="`Séléctionné : ${fluxToValidate} / ${fluxNumber}`"
                    />
                  </v-col>

                  <v-col
                    cols="6"
                    lg="4"
                    class="pb-3"
                    style="padding-top: 10px; padding-bottom: 0"
                  >
                    <v-btn
                      class="btn btn-primary mr-4"
                      type="submit"
                      @click.prevent="validateBrouillard"
                      >Valider le brouillard</v-btn
                    >
                  </v-col>
                  <v-col
                    cols="6"
                    lg="4"
                    class="pb-3"
                    style="padding-top: 10px; padding-bottom: 0"
                  >
                    <v-btn
                      class="btn btn-primary mr-4"
                      @click.prevent="sendNewSearch"
                      :disabled="disabledSearch"
                      >Rechercher</v-btn
                    >
                  </v-col>
                </v-row>
              </ValidateForm>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <ReglementSearch
      :headers="tabHeaders"
      :items="fluxfinancier"
      :loading="loading"
      :panel="panel"
      :areFiltersOpened="panel === 0"
      @removeItems="removeFlux"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import inputText from "@/components/utils/input";
import ValidateForm from "@/components/utils/form";
import ReglementSearch from "@/components/Structure/Finance/reglementSearch.vue";

export default {
  components: {
    inputText,
    ValidateForm,
    ReglementSearch,
    // DatePicker: () =>
    //   import(
    //     /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
    //   ),
  },
  data: () => ({
    mounted: false,
    search: {
      NomStructure: "",
      ID_CodeFederal: "",
      libelle: "",
      PaiementDate: "",
      StartDate: "",
      EndDate: "",
    },
    Saison: 2021,
    panel: 0,
    loading: false,
    fluxfinancier: [],
    sepa: {},
    tabHeaders: [
      {
        text: "Nom Structure",
        value: "structure",
      },
      { text: "Domaine", value: "domaine" },
      { text: "Nombre de flux", value: "nombre" },
      { text: "Montant", value: "montant" },
      { text: "", value: "Select" },
    ],
    data: [],
    fluxNumber: 0,
    fluxToValidate: 0,
  }),

  async created() {
    // this.fluxfinancier = this.items;
    this.sortFluxFinancierDomaine(this.items);
    // this.initData(this.items);
  },

  computed: {
    ...mapGetters("structure", ["currentStructure", "currentStructureId"]),

    disabledSearch() {
      return !(
        this.search.NomStructure !== "" ||
        this.search.structureCode !== "" ||
        this.search.libelle !== "" ||
        this.search.PaiementDate !== "" ||
        (this.search.StartDate !== "" && this.search.EndDate !== "")
      );
    },
    whereClause() {
      if (this.search.NomStructure !== "") {
        return {
          STR_Structure: {
            NomStructure: { _ilike: `%${this.search.NomStructure}%` },
          },
          Z_DateCreation: { _lte: this.whereclause.endDate },
          EST_Reccurent: { _eq: this.whereclause.type },
        };
      }
      if (this.search.structureCode !== "") {
        return {
          STR_Structure: { ID_CodeFederal: { _eq: this.search.structureCode } },
          Z_DateCreation: { _lte: this.whereclause.endDate },
          EST_Reccurent: { _eq: this.whereclause.type },
        };
      }
      if (this.search.StartDate !== "" && this.search.EndDate !== "") {
        return {
          Z_DateCreation: {
            _gte: this.$moment(this.search.StartDate).toISOString(),
            _lte: this.$moment(this.search.EndDate).toISOString(),
          },
          EST_Reccurent: { _eq: this.whereclause.type },
        };
      }
      return {};
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["getBrouillard"]),
    ...mapActions("structure", ["getRibByStructure"]),
    async submit() {
      // this.loading = true;
      // await this.getPlanComptableFiltred({ whereClause: this.whereClause });
      // this.planComptable();
    },
    validateBrouillard() {
      if (this.data.length !== 0) {
        this.initData(this.data);
      } else {
        this.initData(this.fluxfinancier);
      }

      this.$emit("onValidateBrouillard", this.sepa);
    },
    sortFluxFinancierDomaine(items) {
      var fluxstructure = this.groupBy(items, function(item) {
        if (item.STR_Structure && item.LIST_MouvementDomaine)
          return [
            item.STR_Structure.NomStructure,
            item.LIST_MouvementDomaine.id,
          ];
      });

      fluxstructure.map((flux) => {
        var montant = 0;
        var fluxNbr = 0;
        flux.map((entity) => {
          montant += entity.MontantHT;
          fluxNbr++;
        });
        flux.montant = montant;
        flux.fluxNbr = fluxNbr;
      });

      this.fluxfinancier = fluxstructure;
      this.data = fluxstructure;

      this.fluxNumber = this.fluxfinancier.length;
      this.fluxToValidate = this.fluxfinancier.length;
    },

    groupBy(array, fonction) {
      var groups = {};
      array.forEach(function(o) {
        var group = JSON.stringify(fonction(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function(group) {
        return groups[group];
      });
    },

    async sendNewSearch() {
      let fluxfiltered = [];

      if (this.search.NomStructure && this.search.domaine) {
        fluxfiltered = this.fluxfinancier.filter(
          (flux) =>
            flux[0].STR_Structure &&
            flux[0].STR_Structure.NomStructure.includes(
              this.search.NomStructure
            ) &&
            flux[0].LIST_MouvementDomaine.domaine.includes(this.search.domaine)
        );
      } else {
        if (this.search.NomStructure) {
          fluxfiltered = this.fluxfinancier.filter(
            (flux) =>
              flux[0].STR_Structure &&
              flux[0].STR_Structure.NomStructure.includes(
                this.search.NomStructure
              )
          );
        }
        if (this.search.domaine) {
          fluxfiltered = this.fluxfinancier.filter(
            (flux) =>
              flux[0].LIST_MouvementDomaine &&
              flux[0].LIST_MouvementDomaine.domaine.includes(
                this.search.domaine
              )
          );
        }
      }

      this.fluxfinancier = fluxfiltered;
      this.data = fluxfiltered;

      this.fluxNumber = this.fluxfinancier.length;
      this.fluxToValidate = this.fluxfinancier.length;
    },

    erraseFilter() {
      this.search = {};
      this.sortFluxFinancierDomaine(this.items);
    },

    FormatMainDataTags(rib, countFlux, totalAmount) {
      this.sepa.GrpHdr = {};
      const FRST_RCUR = this.whereclause.type == false ? "FRST" : "RCUR";
      const SeqTp = FRST_RCUR;
      this.sepa.GrpHdr.MsgId =
        "PREL FFME - " +
        FRST_RCUR +
        " - " +
        this.$moment(this.whereclause.endDate).format("MM/YYYY");
      this.sepa.GrpHdr.CreDtTm = this.$moment(this.whereclause.endDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      this.sepa.GrpHdr.NbOfTxs = countFlux;
      this.sepa.GrpHdr.InitgPty = {};
      this.sepa.GrpHdr.InitgPty.Nm =
        "FEDERATION FRANCAISE DE LA MONTAGNE ET DE L ESCALADE";

      this.sepa.PmtInf = {};
      this.sepa.PmtInf.PmtInfId = "FFME";
      this.sepa.PmtInf.PmtMtd = "DD";
      this.sepa.PmtInf.BtchBookg = "true";
      this.sepa.PmtInf.NbOfTxs = countFlux;
      this.sepa.PmtInf.CtrlSum = totalAmount;
      this.sepa.PmtInf.PmtTpInf = {};
      this.sepa.PmtInf.PmtTpInf.SvcLvl = {};
      this.sepa.PmtInf.PmtTpInf.SvcLvl.Cd = "SEPA";
      this.sepa.PmtInf.PmtTpInf.LclInstrm = {};
      this.sepa.PmtInf.PmtTpInf.LclInstrm.Cd = "CORE";
      this.sepa.PmtInf.PmtTpInf.SeqTp = SeqTp;
      this.sepa.PmtInf.ReqdColltnDt = this.$moment(
        this.whereclause.endDate
      ).format("YYYY-MM-DD");
      this.sepa.PmtInf.Cdtr = {};
      this.sepa.PmtInf.Cdtr.Nm = "FFME";
      this.sepa.PmtInf.CdtrAcct = {};
      this.sepa.PmtInf.CdtrAcct.Id = {};
      this.sepa.PmtInf.CdtrAcct.Id.IBAN = rib[0].FIN_IbanNumero;
      this.sepa.PmtInf.CdtrAgt = {};
      this.sepa.PmtInf.CdtrAgt.FinInstnId = {};
      this.sepa.PmtInf.CdtrAgt.FinInstnId.BIC = rib[0].FIN_BicNumero;
      this.sepa.PmtInf.ChrgBr = "SLEV";
      this.sepa.PmtInf.CdtrSchmeId = {};
      this.sepa.PmtInf.CdtrSchmeId.Id = {};
      this.sepa.PmtInf.CdtrSchmeId.Id.PrvtId = {};
      this.sepa.PmtInf.CdtrSchmeId.Id.PrvtId.Othr = {};
      this.sepa.PmtInf.CdtrSchmeId.Id.PrvtId.Othr.Id = "FR63ZZZ439062";
      this.sepa.PmtInf.CdtrSchmeId.Id.PrvtId.Othr.SchmeNm = {};
      this.sepa.PmtInf.CdtrSchmeId.Id.PrvtId.Othr.SchmeNm.Prtry = "SEPA";
    },

    FormatFluxDetail(flux, rum, type, date_prel_libelle, rib, totalAmount) {
      let date_Mandat = flux.STR_Structure
        ? this.$moment(flux.STR_Structure.Z_DateCreation).format("YYYY-MM-DD")
        : "2013-09-01";

      flux.DrctDbtTxInf = {};
      flux.PmtId = {};
      flux.PmtId.InstrId =
        "FFME " + date_prel_libelle + " - " + type + " (" + rum + ")";
      flux.PmtId.EndToEndId =
        "FFME " + date_prel_libelle + " - " + type + " (" + rum + ")";
      // if (flux.MontantHT) flux.InstdAmt = flux.MontantHT.toFixed(2);
      flux.InstdAmt = totalAmount.toFixed(2);
      flux.DrctDbtTx = {};
      flux.DrctDbtTx.MndtId = rum;
      flux.DrctDbtTx.DtOfSgntr = date_Mandat;
      flux.DrctDbtTx.AmdmntInd = false;
      flux.DbtrAgt = {};
      (flux.DbtrAgt.FinInstnId = {}),
        (flux.DbtrAgt.FinInstnId.BIC = rib[0].FIN_BicNumero);
      flux.Dbtr = {};
      flux.Dbtr.Nm = flux.STR_Structure?.NomStructure;
      flux.DbtrAcct = {};
      flux.DbtrAcct.Id = {};
      flux.DbtrAcct.Id.IBAN = rib[0].FIN_IbanNumero;
      flux.RmtInf = {};
      flux.RmtInf.Ustrd = rib[0].FIN_NomAgence;

      return flux;
    },

    async initData(response) {
      // let countFlux = 0;
      let totalAmount = 0;
      response.forEach((data) => {
        data.forEach((item) => {
          // countFlux++;
          totalAmount += item.MontantHT;
        });
      });
      let payload = {
        ID_Structure: this.currentStructureId,
      };
      let rib = await this.getRibByStructure(payload);

      this.FormatMainDataTags(rib, this.fluxToValidate, totalAmount);

      const date_prel_libelle = this.$moment(this.whereclause.endDate).format(
        "MM/YYYY"
      );
      this.sepa.DrctDbtTxInf = [];
      const fluxfinanciers = [];

      response.forEach((data) => {
        const type = data[0].LIST_MouvementDomaine.domaine;
        const rum = rib[0].STR_RUM_MODIFICATION
          ? rib[0].STR_RUM_MODIFICATION
          : data[0].STR_Structure?.id;

        let itemAmount = 0;
        data.forEach((item) => {
          itemAmount += item.MontantHT;
        });

        let flux = this.FormatFluxDetail(
          data[0],
          rum,
          type,
          date_prel_libelle,
          rib,
          itemAmount
        );

        fluxfinanciers.push(flux);
      });

      this.sepa.DrctDbtTxInf = fluxfinanciers;
    },

    removeFlux(item) {
      const finalFlux = this.data.filter((flux) => flux[0].id !== item[0].id);
      this.data = finalFlux;
      this.fluxToValidate = finalFlux.length;
    },
  },

  props: {
    items: {
      type: Array,
      default: null,
    },
    openbrouillard: {
      type: Boolean,
      default: false,
    },
    whereclause: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style>
.v-text-field__details {
  display: none;
}

.v-text-field__slot input {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
