var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__reglement_list"},[_c('v-data-table',{class:{
      'filters-opened': _vm.areFiltersOpened,
      'elevation-1 list__table': _vm.scrollTable,
    },attrs:{"headers":_vm.headers,"fixed-header":true,"loading":_vm.loading,"items":_vm.items,"item-key":"id","items-per-page":-1,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.structure",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item[0].STR_Structure && item[0].STR_Structure.NomStructure)+" ")]}},{key:"item.domaine",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item[0].LIST_MouvementDomaine.domaine)+" ")]}},{key:"item.nombre",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.fluxNbr)+" ")]}},{key:"item.montant",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.montant)+" € ")]}},{key:"item.Select",fn:function(ref){
    var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":"true","value":_vm.fluxSelected},on:{"change":function($event){return _vm.disableFlux(item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }