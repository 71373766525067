<template>
  <v-dialog
    v-model="openbrouillard"
    @click:outside="closeModal"
    @keydown.esc="$emit('onClose')"
    max-width="80vw"
  >
    <v-row
      no-gutters
      class="brouillard__layout"
      style="background: #fff !important;"
    >
      <SubBar
        :title="subBarTitle"
        @closeBrouillard="closeBrouillard"
        :closeModal="true"
      />
      <ReglementDetails
        :headers="tabHeaders"
        :items="context"
        :whereclause="where"
        :loading="loading"
        :panel="panel"
        :areFiltersOpened="panel === 0"
        @onValidateBrouillard="validateBrouillard"
      />
    </v-row>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import ReglementDetails from "@/modules/GestionDesFinances/ParametrageComptable/Reglement_detail.vue";

export default {
  components: {
    SubBar,
    ReglementDetails,
  },

  data: () => ({
    subBarTitle: "Détail des règlements",
    mounted: false,
    search: {},
    Saison: 2021,
    panel: 0,
    loading: false,
    tabHeaders: [
      {
        text: "Nom Structure",
        value: "structure",
      },
      { text: "Domaine", value: "domaine" },
      { text: "Nombre de flux", value: "nombre" },
      { text: "Montant", value: "montant" },
      { text: "", value: "Select" },
    ],
  }),

  async created() {},

  computed: {
    disabledSearch() {
      if (this.search.NumeroCompte || this.search.NomLibellee) {
        return false;
      } else return true;
    },
  },

  methods: {
    async submit() {
      // this.loading = true;
      // await this.getPlanComptableFiltred({ whereClause: this.whereClause });
      // this.planComptable();
    },

    validateBrouillard(fluxlist) {
      this.$emit("onValidateBrouillard", fluxlist);
      this.openbrouillard = false;
      this.closeModal();
    },

    closeModal() {
      this.$emit("onClose");
    },

    closeBrouillard() {
      this.openbrouillard = false;
    },
  },
  props: {
    context: {
      type: Array,
      default: null,
    },
    openbrouillard: {
      type: Boolean,
      default: false,
    },
    where: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog--fullscreen {
  background-color: #fff;
  .row.brouillard__layout.no-gutters {
    height: 100%;
  }
}
.loading {
  background: red;
}
</style>
