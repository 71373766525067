<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span v-if="panel === 0">Masquer les filtres</span>
              <span v-else>Afficher les filtres</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="#f0f0f0">
              <ValidateForm @validated="submit" :showFieldObl="false">
                <v-row class="pt-5">
                  <v-col
                    cols="2"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.NumeroCompte"
                      rules="min:2"
                      placeholder="Code analytique"
                    ></inputText>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <inputText
                      v-model="search.NomLibellee"
                      rules="min:3"
                      placeholder="Libellé analytique"
                    ></inputText>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="2"
                    xl="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <DatePicker
                      v-model="search.StartDate"
                      rules="min:3"
                      :maxToday="true"
                      placeholder="Date début"
                    ></DatePicker>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="2"
                    xl="2"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <DatePicker
                      v-model="search.EndDate"
                      rules="min:3"
                      :maxToday="true"
                      placeholder="Date fin"
                    ></DatePicker>
                  </v-col>

                  <v-col cols="4" lg="3">
                    <v-btn
                      @click.prevent="erraseFilter()"
                      outlined
                      color="indigo"
                      >Effacer tous les filtres</v-btn
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    class="pb-3"
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    <v-btn
                      class="btn btn-primary mr-4"
                      type="submit"
                      :disabled="disabledSearch"
                      >Rechercher</v-btn
                    >
                  </v-col>
                </v-row>
              </ValidateForm>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" lg="12">
        <PlanAnalytiqueListSearch
          :headers="tabHeaders"
          :items="allPlanComptable"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inputText from "@/components/utils/input";
import ValidateForm from "@/components/utils/form";
import PlanAnalytiqueListSearch from "@/components/GestionsDesFinances/ParametrageComptable/PlanAnalytiqueListSearch";

export default {
  components: {
    inputText,
    ValidateForm,
    PlanAnalytiqueListSearch,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
  },

  data: () => ({
    listStructuresLigue: [],

    allPlanComptable: [],
    tabHeaders: [
      {
        text: "Code analytique",
        value: "codeComptable",
      },
      {
        text: "Libellée",
        value: "NomLibellee",
      },
      { text: "Solde fiscale", value: "" },

      {
        text: "Solde saison 2021",
        value: "",
      },
    ],
    mounted: false,
    search: {},
    Saison: 2021,
    panel: 0,
    loading: false,
  }),

  async created() {
    await this.setData();
    return;
  },

  computed: {
    ...mapGetters("finances", ["getPlanComptable"]),

    disabledSearch() {
      if (this.search.NumeroCompte || this.search.NomLibellee) {
        return false;
      } else return true;
    },

    whereClause() {
      //all fields search NumeroCompte  (_gte >=)
      if (this.search.NumeroCompte && this.search.NomLibellee) {
        return {
          NumeroCompte: { _gte: this.search.NumeroCompte },
          NomLibellee: { _ilike: `%${this.search.NomLibellee}%` },
        };
      }
      // NumeroCompte
      if (this.search.NumeroCompte) {
        return {
          NumeroCompte: { _gte: this.search.NumeroCompte },
        };
      }
      //NomLibellee
      if (this.search.NomLibellee) {
        return {
          NomLibellee: { _ilike: `%${this.search.NomLibellee}%` },
        };
      }
      return "";
    },
  },

  methods: {
    ...mapActions("finances", ["getPlanComptableFiltred"]),
    erraseFilter() {
      this.search = {};
      this.typeResultat = null;
      this.voyages = [];
    },
    planComptable() {
      if (!this.getPlanComptable) return [];
      const resultSearchPlanComptable = this.getPlanComptable.map(
        (planComptable) => ({
          ...planComptable,
          codeComptable: planComptable.NumeroCompte,
          libellee: planComptable.NomLibellee,
        })
      );
      this.allPlanComptable = [...resultSearchPlanComptable];
      this.loading = false;
    },

    async submit() {
      // this.loading = true;
      // await this.getPlanComptableFiltred({ whereClause: this.whereClause });
      // this.planComptable();
    },
    async setData() {},
  },
};
</script>

<style>
.v-text-field__details {
  display: none;
}

.v-text-field__slot input {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
