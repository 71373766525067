<template>
  <v-row no-gutters>
    <v-col cols="12" sm="4" lg="4">
      <v-row no-gutters class="h-100">
        <v-col cols="12" class="p-4 pl-0">
          <div class="mb-2">
            <h6 class="mb-0">Date fin</h6>
            <DatePicker
              v-model="where.endDate"
              rules="min:3"
              :maxToday="true"
            ></DatePicker>
          </div>

          <div class="mt-5">
            <h6 class="mb-0">Type de fichier SEPA</h6>
            <v-radio-group v-model="where.typeSepa" row>
              <v-radio label="First" value="first"></v-radio>
              <v-radio label="Reccurent" value="reccurent"></v-radio>
            </v-radio-group>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" sm="8" lg="8" class="p-4" style="max-width: 400px;">
      <v-btn
        rounded
        class="btn btn-primary mt-5"
        @click.prevent="extract"
        :disabled="canExtract"
      >
        Extraire les écritures bancaires
      </v-btn>
      <div class="mt-3 pl-3">Date dernier export : {{ last_prelevement }}</div>
      <v-btn
        rounded
        class="btn btn-primary mt-3"
        style="width: 258px; "
        @click.prevent="viewBrouillard"
      >
        Voir le dernier brouillard
      </v-btn>
    </v-col>
    <Brouillard
      v-if="openbrouillard"
      :openbrouillard="openbrouillard"
      :context="fluxfinanciers"
      :where="where"
      @onClose="openbrouillard = false"
      @onValidateBrouillard="validateBrouillard"
    />
  </v-row>
</template>

<script>
// import InputText from "@/components/utils/input.vue";

import { mapGetters, mapActions } from "vuex";
import { json2xml } from "xml-js";
import Brouillard from "@/modules/GestionDesFinances/ParametrageComptable/Flux__Brouillard.vue";
import { saveAs } from "file-saver";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  components: {
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
    Brouillard,
  },

  data: () => ({
    where: {
      endDate: null,
      type: null,
    },
    fluxfinanciers: [],
    sepa: {},
    openbrouillard: false,
    canExtract: true,
    last_prelevement: "",
    // data: {
    //   dateFin: null,
    //   date_Mandat: null,
    //   type: "",
    //   code_rum: "",
    //   InstrId: "",
    //   EndToEndId: "",
    //   MndtId: "",
    //   DtOfSgntr: null,
    //   BIC: null,
    //   Dbtr: "",
    //   IBAN: "",
    // },
  }),

  async created() {
    let payload = {
      id: 1,
    };
    let dateSepa = await this.querySepaPrelevement(payload);
    this.last_prelevement = this.$moment(dateSepa[0].date_prelevement).format(
      "DD/MM/YYYY"
    );
  },

  computed: {
    ...mapGetters("structure", ["currentStructure", "currentStructureId"]),
    ...mapGetters("saisons", ["currentSaison", "selectedSaison"]),
    ...mapGetters("user", ["currentUserId", "currentUser"]),
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getFluxFinancierByDateAndType",
      "createFluxFinancierDetail",
      "updateFluxFinancierprelevement",
    ]),
    ...mapActions("structure", ["getRibByStructure"]),
    ...mapActions("finances", ["updateSepa", "querySepaPrelevement"]),

    async viewBrouillard() {
      let type = false;
      if (this.where.typeSepa == "reccurent") type = true;
      this.where.type = type;
      let dateEnd = this.where.endDate;
      if (dateEnd) {
        // var d = new Date();
        // d.setDate(1);
        // d.setHours(-1);

        // dateEnd = this.$moment(d).format("YYYY-MM-DD");
        let payload = {
          date: dateEnd,
          type: this.where.type,
        };
        try {
          let data = await this.getFluxFinancierByDateAndType(payload);
          // this.fluxfinanciers = await this.formatData(data, dateEnd);
          this.fluxfinanciers = data;
        } catch (e) {
          console.log(e);
        }
        this.openbrouillard = true;
      } else error_notification("Veuillez renseigner une date de fin");
    },

    async extract() {
      let input = this.sepa;

      const amount = input.PmtInf.CtrlSum;
      this.createFluxFinancier(input, amount);

      if (!this.where.type) {
        input.DrctDbtTxInf.map(async (flux) => {
          await this.updateFluxFinancierprelevement({ id: flux.id });
        });
      }

      var options = {
        compact: true,
        ignoreComment: true,
        spaces: 4,
        fullTagEmptyElement: false,
      };

      this.downloadXML(input, options);
      var d = new Date();
      let payload = {
        id: 1,
        date: this.$moment(d).format("YYYY-MM-DD"),
      };

      this.updateSepa(payload);

      this.fluxfinanciers = [];
    },

    validateBrouillard(fluxlist) {
      this.sepa = fluxlist;
      this.canExtract = false;
    },

    async formatData(fluxFinanciers, dateEnd) {
      const date_prel_libelle = this.$moment(dateEnd).format("MM/YYYY");
      let payload = {
        ID_Structure: this.currentStructureId,
      };
      let rib = await this.getRibByStructure(payload);
      const fluxfinanciers = fluxFinanciers.map((flux) => {
        const type = this.OperationType(flux.NomFluxFinancier);
        const rum = rib[0].STR_RUM_MODIFICATION
          ? rib[0].STR_RUM_MODIFICATION
          : flux.STR_Structure?.id;

        let date_Mandat = flux.STR_Structure
          ? this.$moment(flux.STR_Structure.Z_DateCreation).format("YYYY-MM-DD")
          : "2013-09-01";

        flux.dateFin = dateEnd;
        flux.InstrId =
          "FFME " + date_prel_libelle + " - " + type + "(" + rum + ")";
        flux.EndToEndId =
          "FFME " + date_prel_libelle + " - " + type + "(" + rum + ")";
        flux.MndtId = rum;
        flux.DtOfSgntr = date_Mandat;
        flux.BIC = rib[0].FIN_BicNumero;
        flux.Dbtr = flux.STR_Structure?.NomStructure;
        flux.IBAN = rib[0].FIN_IbanNumero;
        return flux;
      });
      return fluxfinanciers;
    },
    OperationType(fluxFinancierName) {
      let type = "";
      if (
        fluxFinancierName.includes("licence") ||
        fluxFinancierName.includes("LICENCE")
      )
        type = "LICENCE";
      else if (fluxFinancierName.includes("COTISATION"))
        type = "COTISATION_CTt_LIGUE";
      else if (fluxFinancierName.includes("FORMATION")) type = "FORMATION";
      else if (fluxFinancierName.includes("COMPETITION")) type = "COMPETITION";
      else type = "ADMINISTRATIF";
      return type;
    },

    downloadXML(input, options) {
      input.DrctDbtTxInf.map((flux) => {
        delete flux.DrctDbtTxInf;
        delete flux.EST_Reglement;
        delete flux.ID_Saison;
        delete flux.ID_Utilisateur;
        delete flux.LIST_MouvementDomaine;
        delete flux.MontantHT;
        delete flux.MontantTTC;
        delete flux.MontantTVA;
        delete flux.NomFluxFinancier;
        delete flux.STR_Structure;
        delete flux.UTI_Utilisateur;
        delete flux.Z_DateCreation;
        delete flux.id;
        delete flux.__typename;

        return flux;
      });

      // var optionsdate = { year: "numeric", month: "long", day: "numeric" };
      // const timeElapsed = Date.now();
      // const today = new Date(timeElapsed).toLocaleDateString(
      //   "fr-FR",
      //   optionsdate
      // );
      var xml = json2xml(input, options);
      xml =
        '<Document xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="urn:iso:std:iso:20022:tech:xsd:pain.008.001.02"><CstmrDrctDbtInitn>' +
        xml +
        "</CstmrDrctDbtInitn></Document>";
      let typePrelevement = "FRT";
      if (this.where.type) typePrelevement = "RCUR";
      const today = this.$moment(this.where.endDate).format("YYYY-MM");
      var filename =
        "PRESEPABNPPARIBAS-" + typePrelevement + "-" + today + ".xml";
      var pom = document.createElement("a");
      var bb = new Blob([xml], { type: "text/plain" });

      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", filename);

      pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
        ":"
      );
      pom.draggable = true;
      pom.classList.add("dragout");

      pom.click();
    },

    async createFluxFinancier(input, amount) {
      let fluxFinancierDetail = [];
      input.DrctDbtTxInf.map((flux) => {
        const fluxFinancier = {
          ID_Saison: this.currentSaison.id,
          ID_Tarif: 0,
          EST_Debit: true,
          NomFluxFinancierDetail: `Prélèvement ${flux.LIST_MouvementDomaine.domaine} - ${flux.STR_Structure?.NomStructure}`,
          ID_Utilisateur: flux.ID_Utilisateur,
          MontantHT: flux.InstdAmt,
          MontantTTC: flux.InstdAmt,
          MontantTVA: 0,
          FIN_CodeAnalytique: 0,
          FIN_CodeCompta: 0,
        };
        fluxFinancierDetail.push(fluxFinancier);
      });
      const fluxPrincipalUser = {
        ID_FluxFinancierDebit: 0,
        ID_Saison: this.currentSaison.id,
        ID_Structure: this.currentStructureId,
        ID_Traitement: 1,
        ID_Utilisateur: this.currentUserId,
        MontantHT: amount,
        MontantTTC: amount,
        MontantTVA: 0,
        EST_Debit: true,
        NomFluxFinancier: `Prélèvement SEPA du ${this.where.endDate}`,
        EST_Reglement: false,
        ID_CategoryFlux: 2,
        MKP_FluxFinancierDetail: {
          data: fluxFinancierDetail,
          on_conflict: {
            constraint: "MKP_FluxFinancierDetail_pkey",
            update_columns: ["ID_Tarif", "MontantHT", "MontantTTC"],
          },
        },
      };

      const insertedFlux = await this.createFluxFinancierDetail({
        flux: { ...fluxPrincipalUser },
      });
    },
  },
};
</script>

<style lang="scss"></style>
