<template>
  <div class="table__structure_list">
    <v-data-table
      :class="{ 'filters-opened': areFiltersOpened }"
      :headers="headers"
      :fixed-header="true"
      :loading="loading"
      :items="items"
      item-key="id"
      class="elevation-1 list__table"
    >
      <!-- <template v-slot:item.factures="{ item }">
        <v-tooltip bottom>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="green"
              outlined
              @click.prevent="visualizeVoyage(item)"
              key="edit"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Visualiser la déclaration de voyage</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="error"
              outlined
              @click.prevent="declineVoyage(item)"
              key="edit"
            >
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </template>
          <span>Déclinez la demande de voyage à l'étranger</span>
        </v-tooltip>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {},
  data: () => ({}),
  methods: {},

  props: {
    items: {
      type: Array,
    },

    headers: {
      type: Array,
    },
    areFiltersOpened: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.list__table .v-data-table-header {
  background: rgba(230, 230, 230, 1);
}
th {
  background: lightgrey !important;
}

.table__structure_list {
  .list__table .v-data-table__wrapper {
    height: calc(100vh - 420px);
  }
  .list__table.filters-opened .v-data-table__wrapper {
    height: calc(100vh - 500px);
  }

  &.buttonLine {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 480px);
    }
  }

  &.membresFederaux {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 310px);
    }
  }
}

/* .listSearch {
    height: calc(100vh - 370px);
    overflow: hidden;
    overflow-y: scroll;
} */
.filters-opened {
  height: calc(100vh - 440px);
}
.periode__class {
  color: lightslategray;
}
</style>
